import { createMuiTheme } from "@material-ui/core/styles";

import { colors, fonts } from "./variables";
import { green, orange, red } from "@material-ui/core/colors";

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: colors.primary,
    secondary: colors.secondary,
    grey: {
      light: '#F1F1F2',
      medium: '#f9f9f9',
      dark: '#333',
    },
    error: {
      main: red[500]
    },
    warning: {
      main: orange[500]
    },
    success: {
      main: green[500]
    }
  },
  typography: {
    fontFamily: fonts.sansSerif,
    button: {
      fontFamily: fonts.sansSerif,
      fontWeight: 700
    }
  },
  overrides: {
    MuiLink: {
      root: {
        color: "#1259A0"
      }
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: colors["black-1"]
        }
      }
    }
  }
});

export default theme;
